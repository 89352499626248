p,
a {
  color: #333;
  &:visited {
    color: #333;
  }
}

.navWrapper {
  position: fixed;
  bottom: 50%;
  transform: translate(0, 50%);
  * {
    transition: box-shadow 500ms ease, background-color 500ms ease,
      left 250ms ease, transform 500ms ease;
  }
  max-width: 12rem;
  @media (max-width: 40em) {
    left: -2.5rem;
  }
}

.nav {
}

.logout {
  margin-top: 2rem;
}

.nav-itens-wrapper {
  --min-width-hover: 350px;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  max-width: 143px;

  &:hover {
    min-width: var(--min-width-hover);
    z-index: 9999;
    background-color: rgba(65, 65, 65, 0.95);
    box-shadow: 0px 0px 15px 20px rgba(65, 65, 65, 0.95);
    border-radius: 0 15px 0 0;
    .nav {
      min-width: var(--min-width-hover);
    }
    .nav-item {
      min-width: var(--min-width-hover);
      box-shadow: 6px 6px 0px 0px var(--clr-icro_light),
        12px 12px 0px 0px #212529;
    }
    .nav-link,
    .nav-select-wrapper {
      background-color: white;
    }
    .nav-select-wrapper {
      i {
        position: absolute;
        right: 1.5rem;
        pointer-events: none;
        transform: rotate(0);
      }
    }
    i {
      color: #333;
    }
    p,
    .nav-select {
      position: relative !important;
      left: 0;
      opacity: 1;
    }
  }
}

.nav-item {
  list-style: none;
  position: relative;
  padding: 0.5rem;
  background-color: var(--clr-icro);
  &:first-of-type {
    border-radius: 0 15px 0 0;
  }
  &:last-of-type {
    border-radius: 0 0 15px 0;
  }
}

.nav-link {
  text-decoration: none;
  justify-content: center;
  align-items: center;
  display: flex;
  gap: 1rem;
  padding: 0.5rem;
  transition: color 250ms ease;
  &:hover {
    p,
    i {
      color: #666;
    }
  }
  p {
    position: absolute;
    left: -500px;
    opacity: 0;
    font-weight: bold;
  }
  i {
    color: white;
    font-size: 1.5rem;
    display: flex;
  }
}

.nav-item-select {
  gap: 1.5rem;
  i {
    display: flex;
    font-size: 1.5rem;
    color: white;
    transform: rotate(-90deg);
  }
}

.nav-select-wrapper {
  padding: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav-select {
  width: 100%;
  position: absolute !important;
  opacity: 0;
  left: -500px;
  border: none;
}

.login-btn-active {
  p {
    color: var(--clr-icro_light);
  }
  i {
    color: #000;
  }
}
