.ticket_resolve-wrapper {
  position: absolute;
  bottom: 0;
  left: 50%;
  background-color: var(--clr-medium-dark);
  padding: 1rem;
  border-radius: 5px 5px 0 0;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.55);
  width: 50%;
  min-width: 666px;
  transform: translate(-50%, 0);
  animation: show 250ms ease;
}

.ticket_resolve-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  svg {
    font-size: 1.5rem;
    transition: transform 250ms ease;
    &:hover {
      cursor: pointer;
      transform: scale(1.05);
    }
  }
}

.ticket_resolve-bottom {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  textarea {
    min-height: 5rem;
    max-height: 5.5rem;
    max-width: 100%;
    min-width: 100%;
    border-radius: 5px;
    padding: 0.5rem;
    &:focus {
      outline: 2px solid black;
    }
  }
}

.ticket_resolve-bottom-buttons_wrapper {
  display: flex;
  justify-content: end;
  gap: 1rem;
  button {
    padding: 0.6rem 1rem;
  }
}

.ticket_resolve-btn_refuse {
  background-color: #666;
}

@keyframes show {
  0% {
    bottom: -500px;
  }
  100% {
    bottom: 0;
  }
}
