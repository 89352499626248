.ticket-card-wrapper {
  padding: 0.5rem;
  background: var(--clr-medium-dark);
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  display: flex;
  flex-direction: column;

  * {
    color: #e3e3e3;
  }
}

.card-top {
}

.card-top-inner {
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 0;
  border-bottom: 1px solid #e3e3e3;
}

.card-bottom {
  padding: 0.5rem 0;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}

.ticket-description-title {
  margin-bottom: 0.5rem;
}

.ticket-text {
  line-height: 125%;
}

.ticket-btn {
  background-color: #262626;
  align-self: flex-end;
  margin-top: 1rem;
  padding: 0.8rem 1.2rem;
  &:hover {
    cursor: pointer;
    background-color: #000;
  }
}

.hidden {
  display: none;
}
