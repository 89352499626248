.nav-ticket {
  min-width: 100%;
  display: grid;
  place-content: center;
  padding: 0.5rem;
}

.buttons-top-ticket-wrapper {
  width: fit-content;
  display: flex;
  gap: 3.5rem;
}

.button-ticket-top {
  padding: 1rem 2rem;
  background-color: var(--clr-icro);
  color: white;
  font-weight: bold;
  transition: background 250ms ease, color 250ms ease;
  &:hover {
    cursor: pointer;
    background-color: var(--clr-icro_light);
  }
}

.button-ticket-active {
  background-color: white;
  outline: 0.9rem solid var(--clr-icro);
  outline-offset: -0.8rem;
  color: var(--clr-icro);
  transition: outline 250ms ease;
  &:hover {
    background-color: white;
    outline: 0.9rem solid var(--clr-icro_light);
    outline-offset: -0.8rem;
  }
}

.tickets-wrapper {
  margin-top: 5rem;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(450px, 1fr));
  gap: 3rem;
}

.empty-ticket {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 2.5rem;
}
