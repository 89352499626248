:root {
  --clr-icro: #770028;
  --clr-icro_light: #b4003c;
  --clr-medium-dark: hsla(0, 0%, 40%, 0.8);
  --clr-white: #fff;

  --spacer-100: 0.25rem;
  --spacer-200: 0.5rem;
  --spacer-400: 1rem;
  --spacer-600: 2.5rem;

  --border-radius: 5px;

  --box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.55);
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Ubuntu", sans-serif;
}

body {
  font-family: "Ubuntu", sans-serif;
  background-color: #414141;
}

button,
input,
textarea {
  border: none;
  border-radius: var(--border-radius);
  padding: var(--spacer-400);
}

button {
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.55);
  background-color: #333;
  color: white;
  transition: background 250ms ease, color 250ms ease;
  &:hover {
    cursor: pointer;
    background-color: #000;
  }
}

.main-content-wrapper {
  margin: 2rem;
  margin-left: 10rem;
  color: white;
  padding: 1rem;
  @media (width < 600px) {
    margin: 0;
  }
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
  scrollbar-width: auto;
  scrollbar-color: #666666 #666666;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 8px;
  height: 4px;
  border-radius: 10px;
}

*::-webkit-scrollbar-track {
  background: #666666;
  border-radius: 10px;
}

*::-webkit-scrollbar-thumb {
  background-color: #666666;
  border-radius: 10px;
  border: 5px solid #323232;
}

// Utilities

.flex {
  display: flex;
}

.space-between {
  justify-content: space-between;
}

.align-center {
  align-items: center;
}
