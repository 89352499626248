.walletCreate__wrapper {
  display: grid;
  place-content: center;
  height: 80dvh;
}

.walletCreate {
  padding: var(--spacer-600);
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius);
}

.walletCreate__title {
  margin-bottom: var(--spacer-600);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: var(--spacer-400);
  svg {
    font-size: 2rem;
  }
}

.walletForm {
  display: grid;
  gap: var(--spacer-400);

  button,
  input,
  textarea,
  select {
    border-radius: var(--border-radius);
    padding: var(--spacer-400);
  }

  textarea {
    resize: vertical;
    min-height: 50px;
    max-height: 125px;
  }
}

.walletForm__item {
  display: grid;
  gap: var(--spacer-100);
}
