.login-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 100vw;
  min-height: 100vh;
}

.login {
  display: flex;
  flex-direction: column;
  width: 600px;
  background: rgba(44, 44, 44, 0.95);
  box-shadow: 0 8px 32px 0 rgba(129, 42, 78, 0.15);
  backdrop-filter: blur(12px);
  -webkit-backdrop-filter: blur(12px);
  border-radius: 10px;
  padding: 4rem;
  img {
    width: 100%;
    margin-bottom: 1rem;
  }
  @media (max-width: 600px) {
    width: 80%;
  }
}

.login-form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  input {
    padding: 0.8rem;
  }
  button {
    background-color: var(--clr-icro);
    color: white;
    padding: 1rem 1.5rem;
    transition: boxShadow 250ms ease, background 250ms ease;
    &:hover {
      box-shadow: 2px 2px 5px black;
      cursor: pointer;
      background-color: var(--clr-icro_light);
    }
  }
  input {
    &:focus {
      outline: 2px solid var(--clr-icro);
    }
  }
}

.login-error {
  input {
    border-bottom: 2px solid red;
  }
}

.login-error-alert {
  color: red;
  font-weight: bold;
}
