.errorsWrapper {
  display: grid;
  grid-template-columns: 1fr;
  gap: var(--spacer-600);
  place-content: center;

  overflow-y: auto;

  margin-top: var(--spacer-600);
}

.errorItem {
  max-height: 500px;
  display: grid;
  gap: 1rem;
  overflow: auto;
  background-color: white;
  border-radius: 5px;
  padding: 1rem;
}
