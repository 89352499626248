.walletWrapper {
  display: grid;
  gap: var(--spacer-400);
  padding: var(--spacer-400);
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius);

  color: var(--clr-white);
  font-size: 1.5rem;
  p,
  h2 {
    font-size: inherit;
    color: inherit;
  }
  h2 {
    font-size: inh;
    padding-block: var(--spacer-200);
    cursor: ew-resize;
  }
  p {
    overflow-x: auto;
  }
}

.walletBalance-add {
  display: flex;
  gap: var(--spacer-400);
}
