.notFound {
  height: 100vh;
  width: 100vw;
  display: grid;
  place-content: center;
  gap: 2rem;
  h2,
  a,
  a:visited {
    color: white;
    text-decoration: none;
  }
  h2 {
    font-size: 2.5rem;
  }
  a {
    display: flex;
    justify-content: center;
    background-color: var(--clr-icro);
    padding: 1rem 1.5rem;
    transition: boxShadow 250ms ease, background 250ms ease;
    &:hover {
      box-shadow: 2px 2px 5px black;
      cursor: pointer;
      background-color: var(--clr-icro_light);
    }
  }
}
